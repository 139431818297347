<template>
  <b-row
    v-if="show"
    class="text-left"
  >
    <b-col
      md="12"
    >
      <b-form-group>
        <b-form-radio-group
          v-model="mode"
          :options="options"
          button-variant="outline-secondary"
          class="rekomendasi-kegiatan-radios"
          size="lg"
          name="rekomendasi"
          buttons
        />
      </b-form-group>
    </b-col>
    <b-col
      v-if="mode == 'yes'"
      cols="12"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(data, index) in daftarRekoLanjut"
          :key="index"
          class="d-flex justify-content-between"
        >
          <b-form-checkbox
            :ref="`reko-yes-${data.rekomendasi_id}`"
            v-model="selectLanjut"
            :value="data.rekomendasi_id"
            plain
          >
            {{ data.rekomendasi }}
          </b-form-checkbox>
          <b-button
            variant="flat-secondary"
            size="sm"
            @click="copyReko(data.rekomendasi)"
          >
            <feather-icon
              icon="CopyIcon"
              size="10"
            />
          </b-button>
        </b-list-group-item>
        <b-list-group-item>
          <b-form-group
            label="Rekomendasi Tambahan"
            label-for="reko_description"
          >
            <b-form-textarea
              id="vi-reko_description"
              v-model="rekoDesc"
              rows="5"
              name="reko_description"
              placeholder="Rekomendasi Tambahan"
            />
          </b-form-group>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      v-else-if="mode == 'no'"
      cols="12"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(data, index) in daftarRekoTidak"
          :key="index"
          class="d-flex justify-content-between"
        >
          <b-form-checkbox
            :ref="`reko-no-${data.rekomendasi_id}`"
            v-model="selectTidak"
            :value="data.rekomendasi_id"
            plain
          >
            {{ data.rekomendasi }}
          </b-form-checkbox>
          <b-button
            variant="flat-secondary"
            size="sm"
            @click="copyReko(data.rekomendasi)"
          >
            <feather-icon
              icon="CopyIcon"
              size="10"
            />
          </b-button>
        </b-list-group-item>
        <b-list-group-item>
          <b-form-group
            label="Rekomendasi Tambahan"
            label-for="reko_description"
          >
            <b-form-textarea
              id="vi-reko_description"
              v-model="rekoDesc"
              rows="5"
              name="reko_description"
              placeholder="Rekomendasi Tambahan"
            />
          </b-form-group>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      v-if="errorStat"
      cols="12"
    >
      <b-alert
        variant="danger"
        show
      >
        <div class="alert-body">
          Error Found: {{ errorMsg }}
        </div>
      </b-alert>
    </b-col>
    <b-col
      v-if="mode"
    >
      <b-button
        variant="outline-success"
        class="mt-0 mt-md-1"
        size="sm"
        @click="updateSave"
      >
        <span>Simpan Rekomendasi</span>
      </b-button>
      <b-button
        v-if="dataSaved"
        variant="flat-success"
        size="sm"
        class="mt-0 mt-md-2 ml-50"
      >
        <feather-icon
          icon="CheckCircleIcon"
          size="14"
        /> Rekomendasi Berhasil Disimpan
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormRadioGroup, BFormGroup, BListGroup, BListGroupItem, BRow, BCol, BFormCheckbox, BFormTextarea, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BAlert,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
  },
  props: {
    programData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      dataSaved: false,
      selectLanjut: [],
      selectTidak: [],
      mode: '',
      targetUrl: '',
      daftarRekoLanjut: [],
      daftarRekoTidak: [],
      rekoDesc: '',
      options: [
        { text: 'Dilanjutkan', value: 'yes', variant: 'text-info' },
        { text: 'Tidak Dilanjutkan', value: 'no', variant: 'text-danger' },
      ],
      errorStat: false,
      errorMsg: '',
    }
  },
  mounted() {
    this.getTemplateReko()
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    getTemplateReko() {
      if (this.programData) {
        if (this.programData.domain === 'APLIKASI') {
          this.targetUrl = 'application-data'
        } else if (this.programData.domain === 'INFRASTRUKTUR') {
          this.targetUrl = 'infrastructure-data'
        }

        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.programData.data_utama_id,
        }

        if (this.programData.domain === 'APLIKASI') {
          params.aplikasi_id = this.programData.aplikasi_id
          params.type = 'app'
        } else if (this.programData.domain === 'INFRASTRUKTUR') {
          params.infrastruktur_id = this.programData.infrastruktur_id
          params.type = 'infra'
        }

        const promise1 = new Promise(resolve => {
          this.$http.get(`/recommendation/${this.targetUrl}/references?token=${localStorage.getItem('userToken')}`)
            .then(res => {
              if (res.data.status === 'success') {
                this.daftarRekoLanjut = []
                this.daftarRekoTidak = []
                res.data.data.ref_recommendations.map(value => {
                  if (value.tipe === 'CONTINUE') {
                    const reko = {
                      rekomendasi_id: value.rekomendasi_id,
                      rekomendasi: value.rekomendasi,
                    }
                    this.daftarRekoLanjut.push(reko)
                  } else if (value.tipe === 'PENDING') {
                    const reko = {
                      rekomendasi_id: value.rekomendasi_id,
                      rekomendasi: value.rekomendasi,
                    }
                    this.daftarRekoTidak.push(reko)
                  }
                  return true
                })
                resolve(true)
              }
            })
        })

        const promise2 = new Promise(resolve => {
          this.$http.get('/recommendation/get-rekomendasi-sementara', {
            params,
          })
            .then(res => {
              if (res.data.status === 'success') {
                this.selectLanjut = []
                this.selectTidak = []
                res.data.data.continue.map(value => {
                  if (this.selectLanjut.indexOf(value.id) === -1) {
                    this.selectLanjut.push(value.id)
                  }
                  return true
                })
                res.data.data.postpone.map(value => {
                  if (this.selectTidak.indexOf(value.id) === -1) {
                    this.selectTidak.push(value.id)
                  }
                  return true
                })
                resolve(true)
              }
            })
        })

        Promise.all([promise1, promise2]).then(values => {
          if (values[0] && values[1]) {
            this.show = true
          }
          return values
        })
      }
    },
    updateSave() {
      const verif = {
        domain: this.programData.domain,
        data_utama_id: this.programData.data_utama_id,
        tipe_rekomendasi: (this.mode === 'yes') ? 1 : 0,
        rekomendasi_id: (this.mode === 'yes') ? this.selectLanjut : this.selectTidak,
      }
      if (verif.rekomendasi_id.length === 0) {
        this.errorStat = true
        this.errorMsg = 'Rekomendasi Belum Dipilih'
      } else {
        if (this.reko_description !== '') {
          verif.rekomendasi_tambahan = this.reko_description
        }
        if (this.programData.domain === 'APLIKASI') {
          verif.aplikasi_id = this.programData.aplikasi_id
        }
        if (this.programData.domain === 'INFRASTRUKTUR') {
          verif.infrastruktur_id = this.programData.infrastruktur_id
        }

        this.sendRekomendasi(verif)
      }
    },
    sendRekomendasi(verif) {
      this.$http.post(`/dispute/add-result?token=${localStorage.getItem('userToken')}`, verif)
        .then(res => {
          if (res.data) {
            this.dataSaved = true
            this.rekoDesc = ''
            this.errorStat = false
            this.errorMsg = ''
            this.getTemplateReko()

            setTimeout(() => {
              this.$emit('result', res.data.data)
              this.dataSaved = false
            }, 2000)
          }
          return res.data.status
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
